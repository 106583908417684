/* eslint-disable import/exports-last */
/* eslint-disable max-lines-per-function */
import * as React from 'react';

import { graphql, navigate } from 'gatsby';

import DynamicZone from '../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../components/floating-button/floating-button';
import Layout from '../components/layout';

const IndexPage = (props) => {
    const components = props.data.cms.homePageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <main className="w-full h-full">
            <Layout location={props.location} locale={props.pageContext.locale}>
                <FloatingButton />
                <DynamicZone components={components}></DynamicZone>
            </Layout>
        </main>
    );
};

export default IndexPage;

export const query = graphql`
    query ($locale: String!) {
        cms {
            homePageTemplate(locale: $locale) {
                components {
                    ... on STRAPI_ComponentComponentsHeader {
                        __typename
                        title
                        headerSubtitle: subtitle
                        primaryImage {
                            url
                        }
                        secondaryImage {
                            url
                        }
                        contentOffset
                        mobileContentOffset
                        backgroundImage {
                            url
                        }
                        ImagesWithScroll {
                            logos {
                                url
                            }
                            Title
                            mobileImages {
                                url
                            }
                        }
                        backgroundImageHeight
                        lottieData
                        fundingSection {
                            Title
                            link
                            images {
                                url
                            }
                            mobileImages {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentComponentsHorizontalScroll {
                        __typename
                        sectionTitle {
                            tailwindClasses
                            title
                            description
                            style
                            ytLink
                        }
                        HorizontalScrollCaseStudy {
                            case_study {
                                slug
                                link
                                logo {
                                    url
                                }
                            }
                        }
                        hasMobileGrayBackground
                        horizontalScrollColor
                        topPusherHeight
                        topPusherHeightMobile
                    }
                    ... on STRAPI_ComponentVerticalTabsVerticalTabs {
                        __typename
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            title
                            style
                            description
                        }
                        VerticalTabContent {
                            titleImage {
                                url
                            }
                            title
                            contentTexts
                            contentImages {
                                url
                            }
                        }
                        VerticalTabTitle {
                            title
                            image {
                                url
                            }
                        }
                        topPusherHeight
                        topPusherHeightMobile
                    }
                    ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
                        __typename
                        tabsDarkBackground
                        tabHeight
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            title
                            style
                            description
                        }
                        middleImage {
                            url
                        }
                        tabs {
                            type
                            title
                            subtitle
                            link
                            labelImage {
                                url
                            }
                            label
                            imagesTitles
                            imagesMetadata
                            images {
                                url
                            }
                            labelColour
                            isLabelImageVisibleOnDesktop
                        }
                        topPusherHeight
                        topPusherHeightMobile
                    }
                    ... on STRAPI_ComponentComponentsTextWithLinkAndChart {
                        __typename
                        textWithLinkAndChartTitle: title
                        link
                        textWithLinkAndChartContent
                        lottieChart
                        topPusherHeight
                        topPusherHeightMobile
                    }
                    ... on STRAPI_ComponentComponentsTextWithTable {
                        __typename
                        textWithTableTitle: title
                        table {
                            url
                        }
                        textWithTableContent
                        topPusherHeight
                        topPusherHeightMobile
                        showImageOnMobile
                    }
                    ... on STRAPI_ComponentSeoSeo {
                        __typename
                        seoDescription
                        seoLink
                        seoTitle
                        seoImages {
                            url
                        }
                    }
                }
            }
        }
    }
`;
